import chroma from "chroma-js"

const primary = "#00a889"
const white = "#ffffff"
const primaryScale = chroma.scale([white, primary]);

const DesignTokens = {
    colors: {
      white: white,
      black: "#000000",
      overlay: {
        image: "linear-gradient(to top, rgba(0, 0, 0, 0.55) 8%, rgba(0, 0, 0, 0) 72%)",
        light: "linear-gradient(0deg, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3))",
        dark: "linear-gradient(0deg, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6))",
        primary: "linear-gradient(0deg, rgba(0, 168, 137, 0.8), rgba(0, 168, 137, 0.8))",
      },
      primary: {
        100: primaryScale(0.2),
        200: primaryScale(0.4),
        300: primaryScale(0.6),
        400: primaryScale(0.8),
        500: primaryScale(1),
      },
      secondary: {
        500: "#ffffff",
      },
      tertiary: {
        100: "#e3e3e3",
        200: "#bdbdbd",
        300: "#969696",
        400: "#707070",
        500: "#000000",
      },
    },
    borderRadius: ["0"],
    spacing: [
      "0rem",
      "0.25rem",
      "0.5rem",
      "0.75rem",
      "1rem",
      "1.25rem",
      "1.5rem",
      "2rem",
      "2.5rem",
      "3rem",
      "4rem",
      "6rem",
    ],
    fontSizes: [
      "0rem",
      "0.5rem",
      "0.75rem",
      "0.875rem",
      "1rem",
      "1.25rem",
      "1.5rem",
      "2rem",
      "2.5rem",
      "3rem",
      "4rem",
      "6rem",
    ],
    shadows: {
      raised:
        "rgba(46, 41, 51, 0.08) 0px 1px 2px, rgba(71, 63, 79, 0.08) 0px 2px 4px;",
      floating:
        "rgba(46, 41, 51, 0.08) 0px 2px 4px, rgba(71, 63, 79, 0.16) 0px 4px 8px;",
      overlay:
        "rgba(46, 41, 51, 0.08) 0px 4px 8px, rgba(71, 63, 79, 0.16) 0px 8px 16px;",
      dialog:
        "rgba(46, 41, 51, 0.08) 0px 4px 16px, rgba(71, 63, 79, 0.16) 0px 8px 24px;",
    },
  }
  
  export default DesignTokens
  