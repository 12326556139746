import React from "react"
import styled from "styled-components"
import DesignTokens from "./designTokens"
import { Container, IsDesktop, IsMobile } from "./utils"
import logo from "../images/logo-multi.svg"
import { ItemsList } from "./utils"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faGlobe } from "@fortawesome/free-solid-svg-icons"
import {
  faInstagram,
  faFacebookF,
  faYoutube,
  faLinkedin,
} from "@fortawesome/free-brands-svg-icons"
import { OutboundLink } from "gatsby-plugin-google-analytics"

const Header = () => (
  <CustomContainer>
    <OutboundLink
      href="https://www.multi-consult.com"
      target="blank"
      rel="noopener nofollow"
    >
      <img src={logo} alt="Multi Talks Logo" />
    </OutboundLink>
    <IsDesktop>
      <CustomItemList>
        <li>
          <OutboundLink
            href="http://www.youtube.com/user/Multiconsultbergamo"
            target="blank"
            rel="noopener nofollow"
          >
            <FontAwesomeIcon icon={faYoutube} size="2x" />
          </OutboundLink>
        </li>
        <li>
          <OutboundLink
            href="https://www.instagram.com/multi_marketing_consulting/"
            target="blank"
            rel="noopener nofollow"
          >
            <FontAwesomeIcon icon={faInstagram} size="2x" />
          </OutboundLink>
        </li>
        <li>
          <OutboundLink
            href="https://www.facebook.com/Multimarketingconsulting/"
            target="blank"
            rel="noopener nofollow"
          >
            <FontAwesomeIcon icon={faFacebookF} size="2x" />
          </OutboundLink>
        </li>
        <li>
          <OutboundLink
            href="http://www.linkedin.com/company/multi-consult-s-r-l-"
            target="blank"
            rel="noopener nofollow"
          >
            <FontAwesomeIcon icon={faLinkedin} size="2x" />
          </OutboundLink>
        </li>
        <li>
          <OutboundLink href="https://www.multi-consult.com">
            MULTI-CONSULT.IT →
          </OutboundLink>
        </li>
      </CustomItemList>
    </IsDesktop>
    <IsMobile>
      <CustomItemList>
        <li>
          <OutboundLink href="https://www.multi-consult.com">
            <FontAwesomeIcon icon={faGlobe} size="2x" />
          </OutboundLink>
        </li>
      </CustomItemList>
    </IsMobile>
  </CustomContainer>
)

const CustomContainer = styled(Container)`
  padding: 1rem 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  img {
    width: 160px;
  }
`

const CustomItemList = styled(ItemsList)`
  display: flex;
  flex-direction: row;
  align-items: center;
  li {
    margin-left: -1px;
    a {
      position: relative;
      font-weight: 900;
      text-decoration: none;
      font-size: ${DesignTokens.fontSizes[3]};
      color: ${DesignTokens.colors.white};
      border: 1px solid ${DesignTokens.colors.primary[300]};
      display: block;
      padding: ${DesignTokens.spacing[2]};
      width: 50px;
      height: 50px;
      display: flex;
      align-items: center;
      justify-content: center;
      &:hover {
        border: 1px solid ${DesignTokens.colors.white};
        z-index: 10;
      }
      @media screen and (min-width: 769px) {
        font-size: ${DesignTokens.fontSizes[4]};
      }
    }
    &:last-child {
      a {
        width: auto;
        padding: 0 ${DesignTokens.spacing[5]};
      }
    }
  }
`

export default Header
