import styled from "styled-components"
import DesignTokens from "./designTokens"

const Container = styled.div`
  margin: 0 1rem;
  @media screen and (min-width: 768px) and (max-width: 1023px) {
    margin: 0 2rem;
    max-width: 960px;
  }
  @media screen and (min-width: 1024px) and (max-width: 1420px) {
    margin: 0 2rem;
    max-width: 1378px;
  }
  @media screen and (min-width: 1421px) {
    margin: 0 auto;
    max-width: 1378px;
  }
`

const ItemsList = styled.ul`
  margin: 0;
  padding: 0;
  list-style-type: none;
  li {
    margin: 0;
  }
`

const IsDesktop = styled.div`
  display: none;
  @media screen and (min-width: 768px) {
    display: block;
  }
`

const IsMobile = styled.div`
  display: block;
  @media screen and (min-width: 768px) {
    display: none;
  }
`

const CustomLink = styled.a`
  color: ${DesignTokens.colors.primary[500]};
  &:hover {
    color: ${DesignTokens.colors.tertiary[500]};
  }
`

const Row = styled.section`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  @media screen and (max-width: 768px) {
    flex-direction: column;
  }
`

const Column = styled.div`
  width: 46%;
  @media screen and (max-width: 768px) {
    width: 100%;
  }
`

const ColumnThird = styled.div`
  width: 32%;
  @media screen and (max-width: 768px) {
    width: 100%;
  }
`

const ColumnSeventh = styled.div`
  width: 62%;
  @media screen and (max-width: 768px) {
    width: 100%;
  }
`

export {
  Container,
  ItemsList,
  CustomLink,
  IsDesktop,
  IsMobile,
  Row,
  Column,
  ColumnThird,
  ColumnSeventh,
}
